<template>
  <v-menu
    bottom
    left
    min-width="200"
    offset-y
    origin="top right"
    transition="scale-transition"
  >
    <template v-slot:activator="{ attrs, on }">
      <v-btn
        class="ml-2"
        min-width="0"
        text
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list
      :tile="false"
      flat
      nav
    >
      <template v-for="(p, i) in profile">
        <v-divider
          v-if="p.divider"
          :key="`divider-${i}`"
          class="mb-2 mt-2"
        />

        <v-list-item
          v-else
          :key="`item-${i}`"
          to="/"
          @click="menuAction(p.action)"
        >
          <v-list-item-title v-text="p.title" />
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
  import Vue from 'vue'
  import { sync } from 'vuex-pathify';
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'DefaultAccount',

    data: () => ({
      profile: [
        // { title: 'Profile' },
        // { title: 'Settings' },
        { divider: true },
        {title: 'Change password', action: 'changePassword'},
        { title: 'Log out', action: 'logout' },
      ],
    }),

    computed: {
      ...mapGetters('auth', ['isAuthenticated']),
      user: sync('profile')
    },

    methods: {
      ...mapActions('auth', ['logout']),
      changePassword() {

      },
      menuAction (name) {
        Vue.$log.info('Menu action' + name)
        switch (name) {
          case 'profile':
            this.$router.push('/profile')
            break
          case 'changePassword':
            this.$router.push({name: 'ChangeMyPassword'})
            break;
          case 'logout':
            this.logout()
            break
        }
        return this.appTitle + ' - ' + this.appTitle
      }
    }
  }
</script>
